import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import Modal from "../Modal"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  createRole,
  openCreateRoleModal,
  resetCreateRoleModalState,
} from "../../state/ModalCreateRoleSlice"
import { RootState } from "../../state/store"
import Divider from "../Divider"
import Input from "../Input"
import ScopeExpansionPanel from "./ScopeExpansionPanel"
import { pushToast } from "../../state/toastSlice"
import { Toast } from "../../util/types"
import { isSuccess } from "../../util/apiClient"
import * as Helper from "./helper"

/**
 * CreateRoleModal: The react modal component to create a new role in the system
 * @returns React component element
 */
export default function CreateRoleModal() {
  // Local field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.CreateRoleModal" })
  const dispatch = useDispatch()

  // Global state
  const { open, scopeMap } = useSelector((root: RootState) => root.createRoleModal)
  const { scopeGroups } = useSelector((root: RootState) => root.roles)

  // Local state
  const [ roleCode, setRoleCode ] = React.useState<string | undefined>()
  const [ displayName, setDisplayName ] = React.useState<string | undefined>()

  // React hooks
  React.useEffect(() => {
    if (isSuccess(scopeGroups)) {
      Helper.createScopeMap(scopeGroups.payload.scopeGroups, dispatch)
    }
  }, [ scopeGroups ])

  // Functions

  /**
   * closeModal: resets local and global states
   */
  const closeModal = () => {
    setRoleCode(undefined)
    setDisplayName(undefined)
    dispatch(resetCreateRoleModalState())
    if (isSuccess(scopeGroups)) Helper.createScopeMap(scopeGroups.payload.scopeGroups, dispatch)
  }

  /**
   * submitSaveRole: Submits the newly created role to be saved to the database
   * @returns void
   */
  const submitSaveRole = () => {
    // Ensure they are not undefined
    if (!roleCode) return
    if (!displayName) return

    // Close the modal
    dispatch(openCreateRoleModal(false))

    // Save the role
    dispatch(createRole({
      code: roleCode,
      display: displayName,
      onSuccess: () => {
        const toast: Toast = {
          type: "success",
          message: translate("Role successfully created!"),
        }
        dispatch(pushToast(toast))
        closeModal()
      },
      onError: () => {
        const toast: Toast = {
          type: "error",
          message: translate("Failed to create role! Please try again later!"),
        }
        dispatch(pushToast(toast))
        closeModal()
      },
    }))
  }

  // The react component elements
  return (
    <Modal
      className="cp_component_create-role-modal"
      title={ translate("Create Role") }
      primaryLabel={ translate("Save Role") }
      secondaryLabel={ translate("Cancel") }
      secondaryAction={ closeModal }
      primaryAction={ submitSaveRole }
      disabled={ (!roleCode || roleCode === "" || !displayName || displayName === "") }
      closeAction={ closeModal }
      open={ open }
      maxWidth="xl"
    >
      <div className="cp_component_create-role-modal_body">
        <section className="cp_component_create-role-modal_body_basics">
          <h5 className="title_large basics-title">
            { translate("Basics") }
          </h5>
          <Divider />
          <div className="basics-input">
            <div className="basics-input_label">
              <p className="label_small">{ translate("Role Code*") }</p>
              <p className="body_small">
                {
                  (roleCode) ? `${ roleCode.length }/140` : "0/140"
                }
              </p>
            </div>
            <Input
              id="input-role-code"
              placeholder={ translate("Enter Role Code") }
              InputProps={ {
                inputProps: { maxLength: 140 },
              } }
              onChange={ (e) => setRoleCode(e.currentTarget.value) }
              value={ roleCode }
              fullWidth={ true }
            />
          </div>
          <div className="basics-input">
            <div className="basics-input_label">
              <p className="label_small">{ translate("Display*") }</p>
              <p className="body_small">
                {
                  (displayName) ? `${ displayName.length }/140` : "0/140"
                }
              </p>
            </div>
            <Input
              id="input-role-display-name"
              placeholder={ translate("Enter Display Name") }
              InputProps={ {
                inputProps: { maxLength: 140 },
              } }
              onChange={ (e) => setDisplayName(e.currentTarget.value) }
              value={ displayName }
              fullWidth={ true }
            />
          </div>
        </section>
        { (scopeMap) && Object.keys(scopeMap).map((key) => (
          <ScopeExpansionPanel summary={ key } scopes={ scopeMap[key] } />
        )) }
      </div>
    </Modal>
  )
}
