/* eslint-disable react/no-unused-prop-types */
import React, { JSX } from "react"
import { NavigateFunction, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import {
  AccountCircleOutlined,
  ListOutlined,
  MailOutline,
  Mail,
  Search,
  TrackChangesOutlined,
  SupervisedUserCircleOutlined,
  SupervisedUserCircle,
  AccountCircle,
  SendOutlined,
  HomeOutlined,
  Home,
  GroupsOutlined,
} from "@mui/icons-material"

import { ReactComponent as SearchAIBeta } from "../../asset/img/ai_search_beta_icon.svg"
import { ReactComponent as SearchAIBetaInactive } from "../../asset/img/ai_search_beta_icon_inactive.svg"

import * as API from "../../util/apiClient"
import InfluentialLogo from "../InfluentialLogo"
import SlidingPanels from "./SlidingPanels"
import { RootRoute, Scope } from "../../util/types"
import { store } from "../../state/store"
import { useSelector } from "../../state/hooks"
import {
  PanelState,
  setPanelOpen,
  togglePanel,
} from "../../state/slidingPanelSlice"

import "./style.sass"

export type Props = {
  navigate: NavigateFunction,
}

function itemIsActive(item: RootRoute, currentRoute: string): boolean {
  const parts = currentRoute.split("/").filter((part) => part !== "").slice(0, 3)

  return parts.includes(item)
}

function navBarItem(
  label: string,
  id: string,
  active: boolean,
  icon: JSX.Element,
  hasScope: boolean,
  navigateFn: () => void,
  activeIcon: JSX.Element,
): JSX.Element | null {
  if (!hasScope) return null

  return (
    <button
      id={ id }
      onClick={ navigateFn }
      type="button"
    >
      <aside className={ active ? "active" : "" }>
        <div>
          { active ? activeIcon : icon }
          <p className="label_extrasmall-caps-semibold nav-text">
            { label }
          </p>
        </div>
      </aside>
    </button>
  )
}

export default function NavigationBar(props: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.NavigationBar" })

  const dispatch = useDispatch()
  const currentRoute = useLocation()
  const panelOpen = useSelector(({ slidingPanels }) => slidingPanels.panelOpen)
  const { user, scopes } = useSelector(({ user: userSlice }) => userSlice)

  // User state is loading... show only logo with only minimal nav items
  if (!API.isSuccess(user)) {
    return (
      <div className="component_navigation-bar">
        <InfluentialLogo />
      </div>
    )
  }

  // NOTE: Using vanity from user call instead of URL here so users who
  // hit a 404 page of any kind may still navigate.
  const vanity = user.payload.currentUser?.customer.vanity

  return (
    <>
      <div className="component_navigation-bar">
        <InfluentialLogo />
        { navBarItem(
          translate("Home"),
          "cp_navigation-bar_button-home",
          panelOpen === PanelState.CLOSED && itemIsActive(
            RootRoute.HOME,
            currentRoute.pathname,
          ),
          <HomeOutlined id="cp_nav-bar_unselected-home-icon" />,
          scopes.includes(Scope.FEATURE_CAMPAIGN), // Home link is always shown
          () => {
            props.navigate(`/${ vanity }/dashboard`)
            dispatch(setPanelOpen(PanelState.CLOSED))
          },
          <Home id="cp_nav-bar_selected-home-icon" />,
        ) }
        { navBarItem(
          translate("Profiles"),
          "cp_navigation-bar_button-profiles",
          (
            panelOpen === PanelState.CLOSED
            && itemIsActive(RootRoute.SOCIAL_PROFILES, currentRoute.pathname)
          ) || panelOpen === PanelState.PROFILES,
          <AccountCircleOutlined />,
          scopes.includes(Scope.FEATURE_INFLUENCERS),
          () => store.dispatch(togglePanel(PanelState.PROFILES)),
          <AccountCircle />,
        ) }
        { navBarItem(
          translate("Search 2.0"),
          "cp_navigation-bar_button-search-ai",
          panelOpen === PanelState.CLOSED && itemIsActive(
            RootRoute.SEARCH_AI,
            currentRoute.pathname,
          ),
          <SearchAIBetaInactive />,
          scopes.includes(Scope.FEATURE_SEARCH_AI),
          () => {
            dispatch(setPanelOpen(PanelState.CLOSED))
            props.navigate(`/${ vanity }/${ RootRoute.SEARCH_AI }`)
          },
          <SearchAIBeta />,
        ) }
        { navBarItem(
          translate("Search"),
          "cp_navigation-bar_button-search",
          panelOpen === PanelState.CLOSED && itemIsActive(
            RootRoute.SEARCH,
            currentRoute.pathname,
          ),
          <Search />,
          scopes.includes(Scope.FEATURE_SEARCH),
          () => {
            dispatch(setPanelOpen(PanelState.CLOSED))
            props.navigate(`/${ vanity }/search`)
          },
          <Search />,
        ) }
        { navBarItem(
          translate("Lists"),
          "cp_navigation-bar_button-lists",
          panelOpen === PanelState.LISTS,
          <ListOutlined />,
          scopes.includes(Scope.FEATURE_INFLUENCER_LIST), // TODO: Filter scopes to determine visibility
          () => store.dispatch(togglePanel(PanelState.LISTS)),
          <ListOutlined />,
        ) }
        { navBarItem(
          translate("Comms"),
          "cp_navigation-bar_button-comms",
          (panelOpen === PanelState.CLOSED && itemIsActive(RootRoute.COMMS, currentRoute.pathname))
          || panelOpen === PanelState.COMMUNICATIONS,
          <MailOutline id="cp_nav-bar_unselected-comms-icon" />,
          scopes.includes(Scope.DEVELOPMENT) && scopes.includes(Scope.FEATURE_COMMUNICATIONS),
          () => store.dispatch(togglePanel(PanelState.COMMUNICATIONS)),
          <Mail id="cp_nav-bar_selected-comms-icon" />,
        ) }
        { navBarItem(
          translate("Campaigns"),
          "cp_navigation-bar_button-campaigns",
          (
            panelOpen === PanelState.CLOSED
            && itemIsActive(RootRoute.CAMPAIGNS, currentRoute.pathname)
          ) || panelOpen === PanelState.CAMPAIGNS,
          <TrackChangesOutlined />,
          scopes.includes(Scope.FEATURE_CAMPAIGN),
          () => store.dispatch(togglePanel(PanelState.CAMPAIGNS)),
          <TrackChangesOutlined />,
        ) }
        { navBarItem(
          translate("Social Tracker"),
          "cp_navigation-bar_button-social-tracker",
          (
            panelOpen === PanelState.CLOSED
            && itemIsActive(RootRoute.SOCIAL_TRACKERS, currentRoute.pathname)
          ) || panelOpen === PanelState.SOCIAL_TRACKER,
          <SendOutlined id="cp_nav-bar_unselected-social-tracker-icon" />,
          scopes.includes(Scope.FEATURE_SOCIAL_TRACKER),
          () => store.dispatch(togglePanel(PanelState.SOCIAL_TRACKER)),
          <SendOutlined id="cp_nav-bar_selected-social-tracker-icon" />,
        ) }
        { navBarItem(
          translate("Customers"),
          "cp_navigation-bar_button-customers",
          panelOpen === PanelState.CLOSED && itemIsActive(
            RootRoute.CUSTOMERS,
            currentRoute.pathname,
          ),
          <SupervisedUserCircleOutlined id="cp_nav-bar_unselected-customers-icon" />,
          (scopes.includes(Scope.FEATURE_CUSTOMER_MANAGEMENT)),
          () => {
            dispatch(setPanelOpen(PanelState.CLOSED))
            props.navigate(`/${ vanity }/customer-management`)
          },
          <SupervisedUserCircle id="cp_nav-bar_selected-customers-icon" />,
        ) }
        { navBarItem(
          translate("Roles"),
          "cp_navigation-bar_button-roles",
          panelOpen === PanelState.CLOSED && itemIsActive(
            RootRoute.ROLES,
            currentRoute.pathname,
          ),
          <GroupsOutlined id="cp_navigation-bar_button-roles_icon" />,
          (scopes.includes(Scope.ROOT)),
          () => {
            dispatch(setPanelOpen(PanelState.CLOSED))
            props.navigate(`/${ vanity }/roles`)
          },
          <GroupsOutlined id="cp_navigation-bar_button-roles_icon" />,
        ) }
      </div>

      <SlidingPanels />
    </>
  )
}
