import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import {
  DataGridPro,
  GridColDef,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro"
import daysjs from "dayjs"
import * as Constant from "../../util/constant"
import * as GraphQL from "../../graphql"
import Pill from "../Pill"

type Props = {
  rawRows: GraphQL.SearchTableRoleQuery["searchRole"]["rows"] | null
  loading: boolean
}

export default function RolesTable({
  rawRows, loading,
}: Props) {
  // Local field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.RolesOverview" })

  // Local state
  const [ sortModel, setSortModel ] = React.useState<GridSortModel>([ {
    field: GraphQL.SearchRoleSort.Code.toLocaleLowerCase(),
    sort: null,
  }, {
    field: GraphQL.SearchRoleSort.Display.toLocaleLowerCase(),
    sort: null,
  }, {
    field: GraphQL.SearchRoleSort.Created.toLocaleLowerCase(),
    sort: null,
  } ])

  // React hooks

  // Build headers for table
  const headers = React.useMemo((): GridColDef[] => ([ {
    field: GraphQL.SearchRoleSort.Code.toLocaleLowerCase(),
    headerName: translate(GraphQL.SearchRoleSort.Code),
    sortable: true,
    disableColumnMenu: true,
    resizable: false,
    renderCell: (params) => (
      <p>{ params.row.code }</p>
    ),
    flex: 1,
  }, {
    field: GraphQL.SearchRoleSort.Display.toLocaleLowerCase(),
    headerName: translate(GraphQL.SearchRoleSort.Display),
    sortable: true,
    disableColumnMenu: true,
    resizable: false,
    renderCell: (params) => (
      <Pill
        id={ `role-display-item-${ params.row.index }` }
        className="cell-pill-color0"
        label={ params.row.display }
      />
    ),
    flex: 1,
  }, {
    field: GraphQL.SearchRoleSort.Created.toLocaleLowerCase(),
    headerName: translate(GraphQL.SearchRoleSort.Created),
    sortable: true,
    disableColumnMenu: true,
    resizable: false,
    renderCell: (params) => (
      <p>{ params.row.created }</p>
    ),
    flex: 1,
  } ]), [ ])

  // Build row data for table
  const rows = React.useMemo(() => (
    (rawRows === null) ? []
      : rawRows.map((row, index): GridValidRowModel | null => ({
        id: row.id,
        code: row.code,
        display: row.display,
        created: daysjs.unix(row.created).format(Constant.MONTH_DAY_ABBR_FORMAT),
        index,
      }))
  ), [ rawRows ])

  // Return the table
  return (
    <DataGridPro
      checkboxSelection={ false }
      columns={ headers }
      rows={ rows }
      loading={ loading }
      sortModel={ sortModel }
      onSortModelChange={ setSortModel }
    />
  )
}
