import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Modal from "../Modal"
import "./style.sass"
import { useSelector, useDispatch } from "../../state/hooks"
import Input from "../Input"
import { closeEditURLModal, updateDeliverableUrl } from "../../state/editURLModalSlice"
import LoadingIndicator from "../LoadingIndicator"
import { pushToast } from "../../state/toastSlice"

function ModalEditURL(): React.JSX.Element {
  const dispatch = useDispatch()
  const {
    url,
    deliverableId,
    open,
    updateCallback,
  } = useSelector(({ editURLModal }) => editURLModal)
  const [ livePostUrl, setLivePostURL ] = useState(url)
  const [ loading, setLoading ] = React.useState(false)

  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalEditURL" })
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })

  const closeModal = () => dispatch(closeEditURLModal())
  const onSubmit = async () => {
    setLoading(true)
    if (deliverableId) {
      await dispatch(updateDeliverableUrl({
        variables: {
          deliverableId,
          livePostUrl,
        },
        onSuccess: async () => {
          setLoading(false)
          await dispatch(closeEditURLModal())
          if (updateCallback) {
            await updateCallback(true)
          }
          await dispatch(pushToast({
            message: translate("Update Success!"),
            type: "success",
          }))
        },
        onError: async () => {
          dispatch(pushToast({
            message: translate("There was an error! Please make sure the url matches the correct format."),
            type: "error",
          }))
          setLoading(false)
        },
      }))
    }
  }
  useEffect(() => {
    if (open) {
      setLivePostURL(url)
    }
  }, [ deliverableId ])
  return (
    <Modal
      className="cp_component-modal-edit-url"
      title={ translate("LIVE post URL") }
      primaryLabel={ loading ? <LoadingIndicator /> : translate("Update") }
      secondaryLabel={ translateCommon("Cancel") }
      open={ open }
      primaryAction={ (onSubmit) }
      closeAction={ closeModal }
      secondaryAction={ closeModal }
      maxWidth="lg"
    >
      <div className="cp_component-modal-edit-url-body">
        <div className="inner-container">
          <Input
            className="input"
            label={ translate("URL") }
            value={ livePostUrl }
            onChange={ (e) => setLivePostURL(e.target.value) }
            fullWidth={ true }
          />
        </div>
      </div>
    </Modal>
  )
}

export default ModalEditURL
