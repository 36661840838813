import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import ReviewerGroupsList from "./ReviewerGroupsList"
import "./reviewer-groups-list.sass"
import { ReviewerGroup } from "../../../../graphqlV2/types/assignReviewerTypes"
import { GET_REVIEWER_GROUPS } from "../../../../graphqlV2/queries/assignReviewersQueries"

interface ReviewerGroupsResponse {
  reviewerGroupsForCampaign: ReviewerGroup[]
}

interface ReviewerGroupsVars {
  campaignId: string
}

export default function ReviewerGroupListContainer() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ selectedGroup, setSelectedGroup ] = useState<ReviewerGroup | null>(null)
  const { campaignID: campaignId } = useParams()
  const query$ = useQuery<ReviewerGroupsResponse, ReviewerGroupsVars>(
    GET_REVIEWER_GROUPS,
    {
      variables: {
        campaignId: campaignId || "",
      },
      context: {
        apiVersion: "v2",
      },
    },
  )

  const groups = query$.data?.reviewerGroupsForCampaign || []
  return (
    <div className="assign-reviewers-tab">
      <ReviewerGroupsList
        loading={ query$.loading }
        groups={ groups }
      />
    </div>
  )
}
