import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"

export type GroupAccountFilters = {
  networks: GraphQL.Network[]
  dateAdded?: string
  listStatus: ("Approved" | "Not Approved")[]
  labelStatus: string[]
  listCategories: string[]
  emailStatus: ("No Email" | "Unsubscribed")[]
}

export interface GroupAccountsFilterModal {
  open: boolean
  selectedFilters: GroupAccountFilters
}

const initialState: GroupAccountsFilterModal = {
  open: false,
  selectedFilters: {
    networks: [],
    listStatus: [],
    labelStatus: [],
    listCategories: [],
    emailStatus: [],
  },
}

export const GroupAccountsFilterModalSlice = createSlice({
  name: "GroupAccountsFilterModal",
  initialState,
  reducers: {
    setModalOpenState: (state, action: PayloadAction<boolean>) => ({
      ...state,
      open: action.payload,
    }),
    setSelectedlFilters: (state, action: PayloadAction<GroupAccountFilters>) => ({
      ...state,
      selectedFilters: action.payload,
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetFilterState: (state) => ({
      open: state.open,
      selectedFilters: {
        networks: [],
        dateAdded: undefined,
        listStatus: [],
        labelStatus: [],
        listCategories: [],
        emailStatus: [],
      },
    }),
  },
})

export const {
  setModalOpenState,
  setSelectedlFilters,
  resetFilterState,
} = GroupAccountsFilterModalSlice.actions

export default GroupAccountsFilterModalSlice.reducer
