import React, {
  useCallback,
  useEffect,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import {
  useParams,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom"
import "./communications-body.sass"

import { EditOutlined, Link as LinkIcon } from "@mui/icons-material"
import dayjs from "dayjs"
import { debounce } from "@mui/material"
import SearchBar from "../SearchBar"
import { useSelector, useDispatch } from "../../state/hooks"
import { Scope } from "../../util/types"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import ErrorHandler from "../ErrorHandler"
import * as Constant from "../../util/constant"
import IconButton from "../IconButton"
import CommunicationsEmailListView from "./CommunicationsEmailListView"
import { THOUSAND } from "../../util/constant"
import { seteditCommGroupID, setCommGroupModalOpen } from "../../state/ModalCommGroupSlice"
import { resetListViewState, setConversationQuery } from "../../state/commEmailListView"
import CommunicationMessages from "./CommunicationMessages"
import CommunicationsBatchListView from "./CommunicationsBatchListView"
import Button from "../Button"
// import CommunicationsDraftListView from "./CommunicationsDraftListView"
// import DraftEditPanelView from "./DraftEditPanelView"
import DraftsFolderView from "./Drafts"
import DraftMessageEditPanel from "./Drafts/DraftEditPanel"

export default function CommunicationsBody() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CommunicationsBody" })
  const [ searchInput, setSearchInput ] = useState("")
  const [ clearInput, setClearInput ] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { vanity, commGroupID } = useParams()
  const [ searchParams ] = useSearchParams()
  const isInboxActive = location.pathname.includes("conversations")
  const isSentActive = location.pathname.includes("sent")
  const isBatchActive = location.pathname.includes("batch")
  const isDraftsActive = location.pathname.includes("drafts")
  const dispatch = useDispatch()
  const { scopes } = useSelector(({ user: userSlice }) => userSlice)
  const { communicationGroup } = useSelector(({ communicationsPage }) => communicationsPage)
  const { conversationQuery } = useSelector(({ commEmailListViewSlice }) => commEmailListViewSlice)

  const handleInputChange = (inputValue: string) => {
    setSearchInput(inputValue)
  }

  const debouncedInput = useCallback(
    debounce((value: string) => {
      dispatch(setConversationQuery(value))
    }, THOUSAND),
    [],
  )

  // Debounce local state value to avoid rapid updates
  useEffect(() => {
    debouncedInput(searchInput)
  }, [ searchInput ])

  // Clear the search input if the conversationQuery if it's null
  // for example when there is a change in the conversationFilter
  useEffect(() => {
    if (!conversationQuery) {
      setClearInput(true)
    } else {
      setClearInput(false)
    }
  }, [ conversationQuery ])

  if (communicationGroup === "init" || communicationGroup === "loading") {
    return <LoadingIndicatorCard />
  }
  if (communicationGroup.status === "error") {
    return <ErrorHandler />
  }

  const onEditClick = () => {
    if (commGroupID) {
      dispatch(seteditCommGroupID(commGroupID))
      dispatch(setCommGroupModalOpen(true))
    }
  }

  const navigateBackToBatch = () => {
    dispatch(resetListViewState())
    navigate(`/${ vanity }/communications/group/${ commGroupID }/batch`)
  }

  const {
    created,
  } = communicationGroup.payload.communicationGroup

  return (
    <div className="cp_component_communications-body-main-container">
      <div className={ `header-section ${ isDraftsActive ? "is-draft" : "" }` }>
        { !isDraftsActive && (
        <SearchBar
          className="search-bar"
          onChange={ (e) => handleInputChange(e.target.value) }
          clearInput={ clearInput }
        />
        ) }
        <div className="details-container">
          <p className="created-label">
            { `${ translate("Created") } ${ dayjs(created * 1000)
              .format(Constant.MONTH_DAY_ABBR_FORMAT) }` }
          </p>
          <div className="campaign-page-button">
            <LinkIcon />
            <p>{ translate("Campaign") }</p>
          </div>
          { scopes.includes(Scope.COMMUNICATIONS_MANAGEMENT) && (
            <IconButton variant="outlined" onClick={ onEditClick }>
              <EditOutlined />
            </IconButton>
          ) }
        </div>
      </div>
      { isBatchActive && searchParams.size > 0 && (
        <div id="breadcrumbs-container" className="cp_component_breadcrumbs-container">
          <Button
            className="back-to-batch-btn"
            isTextButton={ true }
            label={ translate("< Back to Batch View") }
            onClick={ navigateBackToBatch }
          />
        </div>
      ) }
      { isInboxActive && (
      <div id="cp_inbox-communications" className="communications-body-wrapper">
        <CommunicationsEmailListView />
        <CommunicationMessages />
      </div>
      ) }
      { isSentActive && (
      <div id="cp_sent-communications" className="communications-body-wrapper">
        <CommunicationsEmailListView conversationFilter="sent" />
        <CommunicationMessages />
      </div>
      ) }
      { isBatchActive && (
      <div id="cp_batch-communications" className="communications-body-wrapper">
        { searchParams.size > 0 && (
        <>
          <CommunicationsEmailListView conversationFilter="batch" batchId={ searchParams.get("batch_id") } />
          <CommunicationMessages />
        </>
        ) }
        { searchParams.size === 0 && (
        <>
          <CommunicationsBatchListView />
          <CommunicationMessages isBatch={ true } />
        </>
        ) }
      </div>
      ) }
      { isDraftsActive && (
      <div id="cp_drafts-communications" className="communications-body-wrapper">
        <DraftsFolderView />
        <DraftMessageEditPanel />
      </div>
      ) }
      { !isInboxActive && !isSentActive && !isBatchActive && !isDraftsActive && ( // TODO: Add the other views
      <div className="communications-body-wrapper">
        <div className="communications-email-conversation-detail">Placeholder</div>
      </div>
      ) }
    </div>
  )
}
