import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import ToastController from "../../controller/Toast"
import PageShell from "../../component/PageShell"
import RolesOverview from "../../component/RolesOverview"

export default function Roles() {
  const { t: translate } = useTranslation([], { keyPrefix: "page.Roles" })
  return (
    <div className="cp_roles_page">
      <ToastController />
      <PageShell title={ translate("Roles") } />
      <div className="cp_roles_page_container">
        <RolesOverview />
      </div>
    </div>
  )
}
