import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import SearchBar from "../SearchBar"
import Toggle, { ToggleMenuValue } from "../Toggle"
import Button from "../Button"
import { useDispatch, useSelector } from "../../state/hooks"
import { RootState } from "../../state/store"
import * as GraphQL from "../../graphql"
import * as RolesState from "../../state/rolesSlice"
import * as API from "../../util/apiClient"
import * as Constants from "../../util/constant"
import RolesTable from "./table"
import { Scope } from "../../util/types"
import { openCreateRoleModal } from "../../state/ModalCreateRoleSlice"

type RoleToggleValue = "role" | "scope"

/**
 * Component to display roles and be able to search on the roles.  Starting page for role
 * management
 * @returns Roles Page
 */
export default function RolesOverview() {
  // Local field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.RolesOverview" })
  const dispatch = useDispatch()
  const roleToggleItems: ToggleMenuValue[] = [ {
    value: "role",
    label: translate("ROLE"),
    keyId: "toggle-search-roles-role",
  }, {
    value: "scope",
    label: translate("SCOPE"),
    keyId: "toggle-search-roles-scope",
  } ]

  // Global state
  const {
    rolesByRole: roles,
    rolesByScope: scopeRoles,
    scopeGroups,
  } = useSelector((root: RootState) => root.roles)
  const { createdRoleStatus } = useSelector((root: RootState) => root.createRoleModal)
  const { scopes } = useSelector(({ user }) => user)

  // Local state
  const [ toggleValue, setToggleValue ] = React.useState<RoleToggleValue>("role")
  const [ searchInputValue, setSearchInputValue ] = React.useState<string | undefined>()

  // React hooks

  React.useEffect(() => {
    resetPage()
    return resetPage
  }, [])

  React.useEffect(() => {
    if (scopeGroups === "init") dispatch(RolesState.getScopeGroups())
  }, [ scopeGroups ])

  React.useEffect(() => {
    if (toggleValue === "role") {
      if (roles !== "loading") searchRoles(searchInputValue)
    }

    if (toggleValue === "scope") {
      if (scopeRoles !== "loading") searchRoles(searchInputValue)
    }
  }, [ toggleValue ])

  React.useEffect(() => {
    if (API.isSuccess(createdRoleStatus)) {
      searchRoles(searchInputValue)
    }
  }, [ createdRoleStatus ])

  // Functions
  /**
   * Reset the local and global state
   */
  const resetPage = () => {
    setToggleValue("role")
    setSearchInputValue(undefined)
    dispatch(RolesState.resetRolesState())
  }

  /**
   * Performs search using GraphQL to find all the roles with given string
   * @param startsWith The search criteria or undefined for all
   */
  const searchRoles = (startsWith?: string): void => {
    if (startsWith && startsWith.length > 0) {
      if (toggleValue === "role") {
        // Set search parameters
        const params: GraphQL.SearchTableRoleQueryVariables = {
          column: Constants.INITIAL_STATE_ROLE_SEARCH.column,
          direction: Constants.INITIAL_STATE_ROLE_SEARCH.direction,
          startsWith,
          limit: Constants.INITIAL_STATE_ROLE_SEARCH.limit,
          page: Constants.INITIAL_STATE_ROLE_SEARCH.page,
        }

        // Initiate search
        dispatch(RolesState.searchRoles(params))
      } else if (toggleValue === "scope") {
        // Set search parameters
        const params: GraphQL.SearchTableRoleScopeQueryVariables = {
          column: Constants.INITIAL_STATE_ROLE_SEARCH.column,
          direction: Constants.INITIAL_STATE_ROLE_SEARCH.direction,
          startsWith,
          limit: Constants.INITIAL_STATE_ROLE_SEARCH.limit,
          page: Constants.INITIAL_STATE_ROLE_SEARCH.page,
        }

        // Initiate search
        dispatch(RolesState.searchRolesByScope(params))
      }
    } else {
      // Set search parameters
      const params: GraphQL.SearchTableRoleQueryVariables = {
        limit: Constants.INITIAL_STATE_ROLE_SEARCH.limit,
        page: Constants.INITIAL_STATE_ROLE_SEARCH.page,
      }

      // Initiate search
      dispatch(RolesState.searchRoles(params))
    }
  }

  /**
   * The search input change event
   * @param event The event when the search input changes
   */
  const search = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Set the input value
    if (event.target.value.trim() === "") setSearchInputValue(undefined)
    else setSearchInputValue(event.target.value)

    // Call query to search the database for matching values
    searchRoles(event.target.value)
  }

  /**
   * Function to handle creating a new role
   */
  const handleCreateRole = () => {
    // Open the modal
    dispatch(openCreateRoleModal(true))
  }

  // Return JSX for component
  return (
    <div className="cp_component_roles-overview">
      <section className="cp_component_roles-overview_header">
        <div className="cp_component_roles-overview_header_search">
          <SearchBar
            id="search-bar"
            className="search-bar"
            onChange={ (event) => search(event) }
          />
          <Toggle
            toggleOptions={ roleToggleItems }
            value={ toggleValue }
            setValue={ setToggleValue }
          />
        </div>
        <div className="cp_component_roles-overview_header_cta">
          {
            (scopes.includes(Scope.ROOT))
              ? (
                <Button
                  id="create-new-role-button"
                  className="cp_component_roles_create-role-button"
                  label={ translate("+ Create Role") }
                  onClick={ handleCreateRole }
                />
              ) : null
          }
        </div>
      </section>
      <section className="cp_component_roles-overview_body">
        <RolesTable
          rawRows={
            (toggleValue === "role" || !searchInputValue)
              ? (API.isSuccess(roles)) ? roles.payload.searchRole.rows : null
              : (API.isSuccess(scopeRoles)) ? scopeRoles.payload.searchRoleByScope.rows : null
          }
          loading={
            (toggleValue === "role" || !searchInputValue)
              ? (roles === "init" || roles === "loading")
              : (scopeRoles === "init" || scopeRoles === "loading")
          }
        />
      </section>
    </div>
  )
}
