import { gql } from "@apollo/client"
import { USER_ASSIGNMENTS_FRAGMENT } from "../fragments/assignReviewersFragments"

const REMOVE_USER_FROM_GROUP = gql`
    mutation RemoveUserFromGroup($groupId: ID!, $campaignId: ID!, $userId: ID!){
     updateReviewerGroup(input: 
        { groupId: $groupId campaignId: $campaignId 
            userAssignmentUpdates: [ { remove: { userId: $userId } } ]
        }) 
  { 
   reviewerGroup{
        id
        campaignId
      userAssignments {
        ...UserAssignemntsFragment
    }
  } 
  }
}
${ USER_ASSIGNMENTS_FRAGMENT }
`
export default REMOVE_USER_FROM_GROUP
