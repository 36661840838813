import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import NetworkIcon from "../../NetworkIcon"
import { Network } from "../../../graphql"
import Button from "../../Button"
import { useDispatch, useSelector } from "../../../state/hooks"
import * as API from "../../../util/apiClient"
import {
  createOauthRequest,
  setOauthNetwork,
  setOpenOAuthModal,
} from "../../../state/userSlice"

export default function OAuthSettingsTab() {
  // Field variabls/imported functions
  const { t: translate } = useTranslation([], { keyPrefix: "component.OAuthSettingsTab" })
  const dispatch = useDispatch()

  // Bring in global state
  const { currentUser } = useSelector(({ user }) => ({ currentUser: user.user }))

  // Local state
  const [ tiktokHasOAuth, setTiktokHasOAuth ] = React.useState<boolean>(false)
  const [ tiktokUsername, setTiktokUsername ] = React.useState<string>("Username")
  const [ instagramHasOAuth, setInstagramHasOAuth ] = React.useState<boolean>(false)
  const [ instagramUsername, setInstagramUsername ] = React.useState<string>("Username")
  const [ youtubeHasOAuth, setYoutubeHasOAuth ] = React.useState<boolean>(false)
  const [ youtubeUsername, setYoutubeUsername ] = React.useState<string>("Username")

  // React hooks
  React.useEffect(() => {
    if (API.isSuccess(currentUser)) {
      if (currentUser.payload.currentUser) {
        const { customer } = currentUser.payload.currentUser
        if (customer.oauthedTiktokTcmAccount) {
          setTiktokHasOAuth(true)
          setTiktokUsername("The Influential Network, Inc.")
        }
        if (customer.oauthedIgcmAccount) {
          setInstagramHasOAuth(true)
          setInstagramUsername("The Influential Network, Inc.")
        }
        if (customer.oauthedYoutubeDv360Access) {
          setYoutubeHasOAuth(true)
          setYoutubeUsername("The Influential Network, Inc.")
        }
      }
    }
  }, [ ])

  // functions

  const handleOAuthTiktok = () => {
    // Set values
    dispatch(setOauthNetwork(Network.Tiktok))
    dispatch(createOauthRequest({ network: Network.Tiktok }))

    // Open modal
    dispatch(setOpenOAuthModal(true))
  }

  const handleOAuthInstagram = () => {
    // Placeholder
  }

  const handleOauthYouTube = () => {
    // Placeholder
  }

  return (
    <div className="cp_component_oath-tab">
      <section className="cp_component_oath-tab_social-account-container">
        <p className="label_large-bold">{ translate("TikTok Ads Account") }</p>
        <p className="body_medium">
          { translate("Grant Influential limited access to your Tiktok TCM Account for campaign reporting.") }
        </p>
        <div className="network-oauth-container">
          <div className="network-oauth-container_network-info">
            <NetworkIcon network={ Network.Tiktok } isActive={ tiktokHasOAuth } />
            <div>
              { (tiktokHasOAuth)
                ? (
                  <p>{ tiktokUsername }</p>
                ) : (
                  <>
                    <p>{ translate("TikTok Ads Account") }</p>
                    <p className="body_small">{ translate("Not Connected") }</p>
                  </>
                )
              }
            </div>
          </div>
          <div>
            <Button
              id="tiktok-oauth-settings-connect-button"
              label={ (tiktokHasOAuth) ? translate("Reauthorize Account") : translate("Connect Account") }
              onClick={ handleOAuthTiktok }
            />
          </div>
        </div>
      </section>
      <section className="cp_component_oath-tab_social-account-container">
        <p className="label_large-bold">{ translate("Instagram Business Account") }</p>
        <p className="body_medium">
          { translate("Grant Influential access to your Facebook Page with an Instagram Business Account connected.") }
        </p>
        <div className="network-oauth-container">
          <div className="network-oauth-container_network-info">
            <NetworkIcon network={ Network.Instagram } isActive={ instagramHasOAuth } />
            <div>
              { (instagramHasOAuth)
                ? (
                  <p>{ instagramUsername }</p>
                ) : (
                  <>
                    <p>{ translate("Instagram Business Account") }</p>
                    <p className="body_small">{ translate("Not Connected") }</p>
                  </>
                )
              }
            </div>
          </div>
          <div>
            <Button
              id="instagram-oauth-settings-connect-button"
              label={ (instagramHasOAuth) ? translate("Reauthorize Account") : translate("Connect Account") }
              onClick={ handleOAuthInstagram }
            />
          </div>
        </div>
      </section>
      <section className="cp_component_oath-tab_social-account-container">
        <p className="label_large-bold">{ translate("Google Account for YouTube Display and Video 350 (DV360)") }</p>
        <p className="body_medium">
          { translate("Grant Influential limited access to your DV360 account.") }
        </p>
        <div className="network-oauth-container">
          <div className="network-oauth-container_network-info">
            <NetworkIcon network={ Network.Youtube } isActive={ youtubeHasOAuth } />
            <div>
              { (youtubeHasOAuth)
                ? (
                  <p>{ youtubeUsername }</p>
                ) : (
                  <>
                    <p>{ translate("YouTube Account") }</p>
                    <p className="body_small">{ translate("Not Connected") }</p>
                  </>
                )
              }
            </div>
          </div>
          <div>
            <Button
              id="youtube-oauth-settings-connect-button"
              label={ (youtubeHasOAuth) ? translate("Reauthorize Account") : translate("Connect Account") }
              onClick={ handleOauthYouTube }
            />
          </div>
        </div>
      </section>
    </div>
  )
}
