import React, { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import AttachmentIcon from "@mui/icons-material/Attachment"
import { useSelector, useDispatch } from "../../state/hooks"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import Checkbox from "../Checkbox"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import ErrorHandler from "../ErrorHandler"
import InfiniteScroll from "../InfiniteScroll"
import { fetchEmailsList, resetListViewState } from "../../state/commEmailListView"
import { formatTimestampToCustomDate } from "../../util/miscHelper"
import { DEFAULT_CONVERSATION_QUERY } from "../../util/constant"

import "./communications-email-list-view.sass"

interface Conversation {
  id: string,
  handleName: string,
  date: string,
  subject: string,
  body: string,
  hasAttachment: boolean,
}

export default function CommunicationsBatchListView() {
  const navigate = useNavigate()
  const { vanity, commGroupID } = useParams()
  const dispatch = useDispatch()
  const { batches, conversationQuery } = useSelector(({ commEmailListViewSlice }) => commEmailListViewSlice)
  const [ isloading, setIsloading ] = React.useState(false)
  const [ processedConversations, setProcessedConversations ] = React.useState<Conversation[]>([])
  const [ activeCommunication, setActiveCommunication ] = React.useState<string | null>(null)

  const getCurrScrollPage = () => {
    if (processedConversations.length === 0) return 1
    return Math.ceil(processedConversations.length / DEFAULT_CONVERSATION_QUERY.LIMIT) + 1
  }

  const fetchConversations = (page?: number) => {
    if (commGroupID) {
      const conversationInfo: GraphQL.SearchConversationsQueryVariables = {
        communicationGroupId: commGroupID,
        startsWith: conversationQuery || DEFAULT_CONVERSATION_QUERY.STARTS_WITH,
        page: page || getCurrScrollPage(),
        limit: DEFAULT_CONVERSATION_QUERY.LIMIT,
        filter: "batch",
        batchId: DEFAULT_CONVERSATION_QUERY.BATCH_ID,
      }
      dispatch(fetchEmailsList(conversationInfo))
    }
  }

  const clearLocalState = () => {
    // Clear the state
    setProcessedConversations([])
    setActiveCommunication(null)
    dispatch(resetListViewState())
  }

  useEffect(() => {
    if (batches === "init") {
      fetchConversations()
    }

    return clearLocalState
  }, [ ])

  useEffect(() => {
    setIsloading(true)
    setProcessedConversations([])
    fetchConversations(1)
  }, [ conversationQuery ])

  const processConversations = (rows: GraphQL.BatchRowFragment[]) => {
    const nextConversations = rows.map((batch) => ({
      id: batch.id,
      handleName: `Email Batch ... Sent ${ batch.completed }/${ batch.count }`,
      date: formatTimestampToCustomDate(batch.created),
      subject: batch.subject,
      body: "",
      hasAttachment: false,
    }))
    if (getCurrScrollPage() === 1) {
      setIsloading(false)
      setProcessedConversations(nextConversations)
    } else {
      setIsloading(false)
      setProcessedConversations((prev) => [ ...prev, ...nextConversations ])
    }
  }

  const handleActiveCommunication = (id: string) => {
    dispatch(resetListViewState())
    navigate(`/${ vanity }/communications/group/${ commGroupID }/batch?batch_id=${ id }`)
  }

  useEffect(() => {
    if (batches === "init" || batches === "loading") return

    if (API.isSuccess(batches)) {
      const onlyConversations = batches.payload.searchConversation.rows
        .filter((conversation: { __typename: string }) => conversation.__typename === "ConversationBatch")

      processConversations(onlyConversations as GraphQL.BatchRowFragment[])
    }
  }, [ batches ])

  if (batches === "init" || batches === "loading" || isloading) {
    return (
      <div className="communications-email-list-view loading">
        <LoadingIndicatorCard />
      </div>
    )
  }

  if (batches.status === "error") {
    return (
      <div className="communications-email-list-view error">
        <ErrorHandler />
      </div>
    )
  }

  return (
    <div className="communications-email-list-view">
      <section className="email-list-heading"><Checkbox /></section>
      <div className="communications-email-list-infinite-scrolling">
        <InfiniteScroll
          dataLength={ processedConversations.length }
          next={ fetchConversations }
          hasMore={ batches.payload.searchConversation.totalCount > processedConversations.length }
        >
          <section className="email-list-emails">
            { processedConversations.map((conversation, index) => (
              <div
                key={ conversation.id }
                onClick={ () => { handleActiveCommunication(conversation.id) } }
                id={ `cp_component-email-list-email${ activeCommunication === conversation.id ? "-selected" : "" }` }
                className={ `email-list-email ${ activeCommunication === conversation.id ? "selected" : "" }` }
                onKeyDown={ (e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleActiveCommunication(conversation.id)
                  }
                } }
                role="button"
                tabIndex={ 0 }
              >
                <div className="email-list-email-selector">
                  <Checkbox id={ `email-cb-${ index }` } checked={ conversation.id === activeCommunication } />
                </div>
                <div className="email-content">
                  <div className="email-content-top">
                    <p className="handle-name">{ conversation.handleName }</p>
                    <div className="email-content-top-right">
                      { conversation.hasAttachment && (
                        <div className="email-has-attachment"><AttachmentIcon className="email-attachment-icon" /></div>
                      ) }
                      <p className="date">{ conversation.date }</p>
                    </div>
                  </div>
                  <p className="subject">{ conversation.subject }</p>
                  <p className="body">{ conversation.body }</p>
                </div>
              </div>
            )) }
          </section>
        </InfiniteScroll>
      </div>
    </div>
  )
}
