import { gql } from "@apollo/client"
import { REVIEWER_GROUPS_FRAGMENT } from "../fragments/assignReviewersFragments"

export const GET_REVIEWER_GROUPS = gql`
  query GetReviewerGroups($campaignId: ID!) {
    reviewerGroupsForCampaign(campaignId: $campaignId) {
    ...ReviewerGroupsFragment
    }
  }
    ${ REVIEWER_GROUPS_FRAGMENT }
`
export default GET_REVIEWER_GROUPS
