import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"

import * as SearchGQL from "../../graphql/search"
import * as GraphQL from "../../graphql"
import * as SearchHelper from "./helper"
import { setSelectedAccounts as setListSelectedAccounts } from "../listAddAccount"
import { RootState } from "../store"
import { Status } from "../../util/types"
import { search2Accounts, search2Posts } from "../../util/apiClient/queries.search"
import * as API from "../../util/apiClient"

// Search Slice Interface and Initial State
export interface SearchAIState {
  searchAIInput: SearchHelper.SearchInput
  searchResultsAccounts: Status<SearchGQL.Search2AccountsQuery>
  searchResultsContent: Status<SearchGQL.Search2PostsQuery> // GraphQL.Post[],
  searchStatus: any // | Status<GraphQL.SearchInfluencerContentQuery>,
  selectedAccounts: SearchHelper.Accounts,
  selectedPosts: SearchHelper.Posts,
  audienceLocations: Status<GraphQL.SearchAudienceLocationsQuery>,
  selectedLocations: SearchGQL.MinimumLocationMatch[],
  brands: Status<GraphQL.SearchBrandsQuery>,
  imageTags: Status<GraphQL.SearchImageTagsQuery>,
}

const initialState: SearchAIState = {
  searchAIInput: SearchHelper.initialSearchState(),
  searchResultsAccounts: "init",
  searchResultsContent: "init",
  searchStatus: "init",
  selectedAccounts: [],
  selectedPosts: [],
  audienceLocations: "init",
  brands: "init",
  imageTags: "init",
  selectedLocations: [],
}

// Search Slice
export const searchAISlice = createSlice({
  name: "SearchAI",
  initialState,
  reducers: {
    setSearchInput: (
      state,
      action: PayloadAction<SearchHelper.SearchInput>,
    ) => ({
      ...state,
      searchAIInput: action.payload,
    }),
    setSearchResultsAccounts: (
      state,
      action: PayloadAction<Status<SearchGQL.Search2AccountsQuery>>,
    ) => ({
      ...state,
      searchResultsAccounts: action.payload,
    }),

    setSearchResultsContent: (
      state,
      action: PayloadAction<Status<SearchGQL.Search2PostsQuery>>,
    ) => ({
      ...state,
      searchResultsContent: action.payload,
    }),
    setSearchStatus: (
      state,
      action: PayloadAction<Status<any>>, // TODO
    ) => ({
      ...state,
      searchStatus: action.payload,
    }),
    setSelectedAccounts: (
      state,
      action: PayloadAction<SearchHelper.Accounts>,
    ) => ({
      ...state,
      selectedAccounts: action.payload,
    }),
    setSelectedPosts: (
      state,
      action: PayloadAction<SearchHelper.Posts>,
    ) => ({
      ...state,
      selectedPosts: action.payload,
    }),
    setAudienceLocations: (
      state,
      action: PayloadAction<Status<GraphQL.SearchAudienceLocationsQuery>>,
    ) => ({
      ...state,
      audienceLocations: action.payload,
    }),

    setBrands: (
      state,
      action: PayloadAction<Status<GraphQL.SearchBrandsQuery>>,
    ) => ({
      ...state,
      brands: action.payload,
    }),

    setImageTags: (
      state,
      action: PayloadAction<Status<GraphQL.SearchImageTagsQuery>>,
    ) => ({
      ...state,
      imageTags: action.payload,
    }),

    setLocations: (
      state,
      action: PayloadAction<Status<GraphQL.SearchLocationsQuery>>,
    ) => ({
      ...state,
      locations: action.payload,
    }),
    setSelectedLocations: (
      state,
      action: PayloadAction<SearchGQL.MinimumLocationMatch[]>,
    ) => ({
      ...state,
      selectedLocations: action.payload,
    }),
  },
})

export const {
  setSearchInput,
  setSearchResultsAccounts,
  setSearchResultsContent,
  setSearchStatus,
  setSelectedAccounts,
  setSelectedPosts,
  setAudienceLocations,
  setLocations,
  setImageTags,
  setSelectedLocations,
  setBrands,
} = searchAISlice.actions

export default searchAISlice.reducer

// Search Slice Thunks
export const clearSearchState = () => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setSearchInput(SearchHelper.initialSearchState()))
  dispatch(setSearchResultsAccounts("init"))
  dispatch(setSearchResultsContent("init"))
  dispatch(setSelectedAccounts([]))
  dispatch(setSelectedPosts([]))
  dispatch(setListSelectedAccounts([]))
}

export const fetchAccountSearchResults = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  const { searchAI } = await getState()

  dispatch(setSearchResultsAccounts("loading"))
  const newInput = SearchHelper.cloneSearchInput(searchAI.searchAIInput)
  if (newInput.audienceParams.locationMinimum) {
    newInput.audienceParams.locationMinimum = newInput.audienceParams.locationMinimum.map((locationMin) => {
      const newlocationMin = {
        match: locationMin.match,
        location: {
          code: locationMin.location.code,
          name: locationMin.location.name,
          type: locationMin.location.type,
        },
      }
      return newlocationMin
    })
  }
  const results = await search2Accounts({
    input: {
      prompts: searchAI.searchAIInput.prompts.map((prompt) => prompt.value),
      username: searchAI.searchAIInput.username,
      networks: searchAI.searchAIInput.socialNetworks,
      profileParams: searchAI.searchAIInput.profileParams,
      contentParams: searchAI.searchAIInput.contentParams,
      audienceParams: newInput.audienceParams,
    },
  })

  dispatch(setSearchResultsAccounts(results))
}

export const fetchContentSearchResults = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  const { searchAI } = await getState()

  dispatch(setSearchResultsContent("loading"))

  const results = await search2Posts({
    input: {
      prompts: searchAI.searchAIInput.prompts.map((prompt) => prompt.value),
      username: searchAI.searchAIInput.username,
      networks: searchAI.searchAIInput.socialNetworks,
      profileParams: searchAI.searchAIInput.profileParams,
      contentParams: searchAI.searchAIInput.contentParams,
      audienceParams: searchAI.searchAIInput.audienceParams,
    },
  })

  dispatch(setSearchResultsContent(results))
}

export const fetchAudienceLocations = (
  startsWith: string,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setAudienceLocations("loading"))
  const audienceLocationsResult = await API.fetchAudienceLocations(startsWith)
  dispatch(setAudienceLocations(audienceLocationsResult))
}

export const fetchBrands = (
  startsWith: string | null,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setBrands("loading"))
  const brandsResults = await API.fetchBrands(startsWith)
  dispatch(setBrands(brandsResults))
}

export const fetchImageTags = (
  startsWith: string | null,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setImageTags("loading"))
  const imageTagResults = await API.fetchImageTags(startsWith)
  dispatch(setImageTags(imageTagResults))
}
