import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { CreateRoleMap, Status } from "../../util/types"
import { RootState } from "../store"

/**
 * The state for creating a role in the ModalCreateRole component
 */
export interface CreateRoleState {
  open: boolean
  createdRoleStatus: Status<GraphQL.CreateRoleMutation | null>
  scopeMap?: CreateRoleMap
}

// Initiali state
const initialState: CreateRoleState = {
  open: false,
  createdRoleStatus: "init",
  scopeMap: undefined,
}

/**
 * Reducers for the redus state
 */
export const createRoleStateSlice = createSlice({
  name: "createRoleSlice",
  initialState,
  reducers: {
    resetCreateRoleModalState: () => initialState,
    openCreateRoleModal: (state, action: PayloadAction<boolean>) => ({ ...state, open: action.payload }),
    setCreateRoleStatus: (state, action: PayloadAction<Status<GraphQL.CreateRoleMutation | null>>) => ({
      ...state,
      createdRoleStatus: action.payload,
    }),
    updateRoleMap: (state, action: PayloadAction<CreateRoleMap>) => ({ ...state, scopeMap: action.payload }),
  },
})

// Export the functions for use in components
export const {
  resetCreateRoleModalState,
  openCreateRoleModal,
  setCreateRoleStatus,
  updateRoleMap,
} = createRoleStateSlice.actions
export default createRoleStateSlice.reducer

/**
 * This creates a new role in the database
 * @param params All neccessary date to create role and respond back to caller
 * @returns void
 */
export const createRole = (params: {
  code: string,
  display: string,
  onSuccess: () => void,
  onError: () => void,
}) => async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
  // Set status to loading for creating role
  dispatch(setCreateRoleStatus("loading"))

  // Bring in current state
  const { createRoleModal } = await getState()
  const { scopeMap: map } = createRoleModal

  if (map) {
    // Create the data
    const createRoleInput: GraphQL.CreateRoleMutationVariables = {
      code: params.code,
      display: params.display,
      scopeIds: Object.keys(map).flatMap((key) => map[key].filter((item) => item.enabled).map((item) => item.id.toString())),
    }

    // Create the role
    const role = await API.createRole(createRoleInput)

    // Respond to client for success/failure
    if (API.isSuccess(role)) params.onSuccess()
    if (API.isError(role)) params.onError()

    // Set the status
    dispatch(setCreateRoleStatus(role))
  }
}
