import React, { JSX, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Add } from "@mui/icons-material"

import { setModalNewTrackerOpen } from "../../../../state/ModalNewTrackerSlice"
import SlidingPanel from "../../../SlidingPanel"
import "./style.sass"
import SearchBar from "../../../SearchBar"
import EmptySplash from "../../../EmptySplash"
import Button from "../../../Button"
import { useDispatch, useSelector } from "../../../../state/hooks"
import { getSocialTrackers } from "../../../../state/slidingPanelSlice/socialTracker"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import ErrorHandler from "../../../ErrorHandler"
import SocialTrackerCard from "./SocialTrackerCard"
import InfiniteScroll from "../../../InfiniteScroll"
import { setSocialTrackerSearchInput } from "../../../../state/slidingPanelSlice"

type Props = {
  open: boolean
  onClose: () => void
}

function SocialTrackerPanel({
  open,
  onClose,
}: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.NavigationBar" })

  const dispatch = useDispatch()
  const { socialTrackers, socialTrackerSearchInput } = useSelector(({ slidingPanels }) => (slidingPanels))

  const handleCreateTracker = () => {
    dispatch(setModalNewTrackerOpen(true))
  }

  useEffect(() => {
    if (open) {
      dispatch(getSocialTrackers(socialTrackerSearchInput))
    }
  }, [ open, socialTrackerSearchInput ])

  const renderContent = () => {
    if (socialTrackers === "init" || socialTrackers === "loading") {
      return <LoadingIndicatorCard id="cp_component_social-tracker-loading-card" />
    }
    if (socialTrackers.status === "error") {
      return <ErrorHandler err={ socialTrackers } />
    }
    const trackers = socialTrackers.payload.searchContentAlerts
    if (trackers.length === 0) {
      return (
        <div className="cp_component_social-tracker-slide-out-panel-empty-container">
          { socialTrackerSearchInput
            ? <EmptySplash bodyText={ translate("No trackers found!") } />
            : <EmptySplash bodyText={ translate("You have no trackers!") } />
          }
          <Button
            className="create-tracker-button"
            label={ (
              <div className="label-container">
                <Add />
                { translate("Create Tracker") }
              </div>
              ) }
            isPrimary={ false }
            onClick={ handleCreateTracker }
          />
        </div>
      )
    }
    return (
      <InfiniteScroll
        dataLength={ trackers.length }
        next={ () => {} }
        hasMore={ false }
      >
        <div className="cp_component_social-tracker-slide-out-panel-container">
          { trackers.map((tracker: any) => (
            <SocialTrackerCard
              id="cp_component_social-tracker-slide-out-card"
              socialTracker={ tracker }
              closeSlider={ onClose }
            />
          )) }
        </div>
      </InfiniteScroll>
    )
  }

  return (
    <SlidingPanel
      title={ translate("Social Tracker") }
      open={ open }
      onClose={ onClose }
      disablePortal={ true }
    >
      <SearchBar
        onChange={ (e) => dispatch(setSocialTrackerSearchInput(e.target.value)) }
        lastSubmittedSearch={ socialTrackerSearchInput }
      />
      { renderContent() }
    </SlidingPanel>
  )
}

export default SocialTrackerPanel
