import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status } from "../../util/types"

interface RolesState {
  rolesByRole: Status<GraphQL.SearchTableRoleQuery>
  rolesByScope: Status<GraphQL.SearchTableRoleScopeQuery>
  scopeGroups: Status<GraphQL.ScopeGroupsQuery>
}

const initState: RolesState = {
  rolesByRole: "init",
  rolesByScope: "init",
  scopeGroups: "init",
}

export const rolesSlice = createSlice({
  name: "rolesSlice",
  initialState: initState,
  reducers: {
    setRoles: (state, action: PayloadAction<Status<GraphQL.SearchTableRoleQuery>>) => ({
      ...state,
      rolesByRole: action.payload,
    }),
    setRolesByScope: (state, action: PayloadAction<Status<GraphQL.SearchTableRoleScopeQuery>>) => ({
      ...state,
      rolesByScope: action.payload,
    }),
    setScopeGroups: (state, action: PayloadAction<Status<GraphQL.ScopeGroupsQuery>>) => ({
      ...state,
      scopeGroups: action.payload,
    }),
    resetRoles: (state) => ({ ...state, rolesByRole: initState.rolesByRole }),
    resetRolesByScope: (state) => ({ ...state, rolesByScope: initState.rolesByScope }),
    resetRolesState: () => initState,
  },
})

export const {
  setRoles,
  setRolesByScope,
  resetRolesState,
  resetRoles,
  resetRolesByScope,
  setScopeGroups,
} = rolesSlice.actions
export default rolesSlice.reducer

export const searchRoles = (params: GraphQL.SearchTableRoleQueryVariables) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setRoles("loading"))
  const roles = await API.searchRoleTable(params)
  dispatch(setRoles(roles))
}

export const searchRolesByScope = (
  params: GraphQL.SearchTableRoleScopeQueryVariables,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setRolesByScope("loading"))
  const roles = await API.searchRoleTableByScope(params)
  dispatch(setRolesByScope(roles))
}

export const getScopeGroups = () => async (dispatch: Dispatch) => {
  // Indicate that it's loading
  dispatch(setScopeGroups("loading"))

  // Make query
  const groups = await API.getScopeGroups()

  // Set scope groups
  dispatch(setScopeGroups(groups))
}
