import React from "react"
import "./style.sass"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { useTranslation } from "react-i18next"
import { CreateRoleMap, ScopeContent } from "../../util/types"
import Checkbox from "../Checkbox"
import { useDispatch, useSelector } from "../../state/hooks"
import { RootState } from "../../state/store"
import Switch from "../Switch"
import * as MiscHelper from "../../util/miscHelper"
import * as Helper from "./helper"
import { updateRoleMap } from "../../state/ModalCreateRoleSlice"

/**
 * Props: The properties needed for this component
 */
export type Props = {
  summary: string
  scopes: ScopeContent[]
}

/**
 * ScopeExpansionPanel: Creates a expansion panel for all the scopes for a given group
 * @param param0 The properties passed to the component (group name(summary) and scopes)
 * @returns Component elements
 */
export default function ScopeExpansionPanel({ summary, scopes }: Props) {
  // Local variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.CreateRoleModal" })
  const dispatch = useDispatch()

  // Global state
  const { scopeMap } = useSelector((root: RootState) => root.createRoleModal)

  // Local state
  const [ allTogglesSwitched, setAllTogglesSwitched ] = React.useState<boolean>(false)
  const [ activeToggleCount, setActiveToggleCount ] = React.useState<number>(0)

  // React hooks

  /**
   * Creates a two dimensional array that helps with generating the view with
   * 2 scopes per row.
   */
  const scopePairs = React.useMemo((): ScopeContent[][] => {
    // Create 2 dimensional array to hold pairs
    const pairs: ScopeContent[][] = []

    // Loop through scopes and create pairs (count active toggles)
    for (let i = 0; i < scopes.length; i += 2) {
      // Check end of array
      if ((i + 1) >= scopes.length) pairs.push([ scopes[i] ])
      else pairs.push([ scopes[i], scopes[(i + 1)] ])
    }

    // Check to see if all toggled
    if (Helper.areAllTogglesSwitched(scopes)) setAllTogglesSwitched(true)
    setActiveToggleCount(Helper.getActiveToggles(scopes))

    // Return pairs
    return pairs
  }, [ scopes ])

  /**
   * handleAllTogglesChange: Toggles all the scopes to enabled/disabled
   */
  const handleAllTogglesChange = () => {
    if (scopeMap) {
      // Copy the object
      const newScopeMap = MiscHelper.copyObject<CreateRoleMap>(scopeMap)

      // Set all toggles to active
      const updatedScopes: ScopeContent[] = Helper.toggleAllScopes(scopes, allTogglesSwitched)

      // Set new scope map
      newScopeMap[summary] = updatedScopes
      dispatch(updateRoleMap(newScopeMap))
      setAllTogglesSwitched(!allTogglesSwitched)
    }
  }

  /**
   * toggleSwitch: Toggles a single scope to enabled/disabled
   * @param id The id of the scope
   */
  const toggleSwitch = (id: number) => {
    if (scopeMap) {
      // Copy the object
      const newScopeMap = MiscHelper.copyObject<CreateRoleMap>(scopeMap)

      // Update the toggled item
      const updatedScopes: ScopeContent[] = Helper.toggleScope(scopes, id)

      // Set new scope map
      newScopeMap[summary] = updatedScopes
      dispatch(updateRoleMap(newScopeMap))
    }
  }

  // Return all the react elements
  return (
    <Accordion className="pm_component_scope-exp-panel">
      <AccordionSummary
        className="pm_component_scope-exp-panel_summary"
        expandIcon={ <ArrowForwardIosIcon /> }
        aria-controls={ `panel-${ summary.toLowerCase().replaceAll(" ", "_") }-content` }
        id={ `panel-${ summary.toLowerCase().replaceAll(" ", "_") }-header` }
      >
        <h5 className="title_large header-title">
          { summary }
        </h5>
        <div className="details-container">
          <Checkbox
            id={ `${ summary.toLowerCase().replaceAll(" ", "_") }-toggle-all-checkbox` }
            className="pm_component_scope-exp-panel_summary_details_checkbox"
            checked={ allTogglesSwitched }
            onClick={ (e) => { e.stopPropagation() } }
            onChange={ handleAllTogglesChange }
          />
          <span className="details-message">
            {
            `${ activeToggleCount }/${ scopes.length } 
            | ${ translate("Enabled") } `
            }
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails className="pm_component_scope-exp-panel_details">
        { scopePairs.map((pair) => (
          <div className="scope-pair">
            { pair.map((item) => (
              <div className="scope-pair_scope">
                <div className="scope-pair_scope_name-description">
                  <h5 className="title_medium">{ item.code.toUpperCase() }</h5>
                  <p className="body_medium">{ item.description }</p>
                </div>
                <Switch id={ `switch-${ item.code }` } handleChange={ () => toggleSwitch(item.id) } isChecked={ item.enabled } />
              </div>
            )) }
          </div>
        )) }
      </AccordionDetails>
    </Accordion>
  )
}
