import React, {
  JSX,
  useEffect,
  useState,
} from "react"
import "./style.sass"
import {
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
  AccountCircle,
  ArrowDropDown,
  EditOutlined,
  InsertPhotoOutlined,
} from "@mui/icons-material"
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { GridRowParams } from "@mui/x-data-grid-pro"
import { useDispatch, useSelector } from "../../../../state/hooks"
import { fetchSearchResults, setSearchInput } from "../../../../state/searchSlice"
import { addSocialAccounts } from "../../../../state/communicationsPage"
import SearchBar from "../../../SearchBar"
import IconButton from "../../../IconButton"
import { Toast } from "../../../../util/types"
import Button from "../../../Button"
import SearchFilter from "../../../SearchFilter"
import SearchFilterTabs from "../../../SearchFilterTabs"
import SearchResultsTable from "../../../SearchResultsTable"
import { pushToast } from "../../../../state/toastSlice"
import { seteditCommGroupID, setCommGroupModalOpen } from "../../../../state/ModalCommGroupSlice"
import Dropdown from "../../../Dropdown"
import * as GraphQL from "../../../../graphql"
import * as SearchHelper from "../../../../util/searchHelper"
import { RootState } from "../../../../state/store"

/**
 * Component to render the content for group accounts for a specific campaign and/or list of accounts
 * @returns Renders the content of the group accounts
 */
export default function GroupAccountsSearch() {
  // ************************ Component variables ************************
  const navigate = useNavigate()
  const { t: translate } = useTranslation([], { keyPrefix: "component.GroupAccountsSearch" })
  const dispatch = useDispatch()
  const { commGroupID, vanity } = useParams()
  const [ searchParams, setSearchParams ] = useSearchParams()

  // ************************ Selectors ************************
  const { selectedAccounts, searchInput } = useSelector(({ search }) => search)
  const { groupAccountRows: rawRows } = useSelector((root: RootState) => root.communicationsPage)

  // ************************ Local state ************************
  const [ addToGroupDisabled, setAddToGroupDisabled ] = useState<boolean>(true)
  const [ searchInputValue, setSearchInputValue ] = useState<string>("")

  // ************************ React hooks ************************
  useEffect(() => {
    if (selectedAccounts.length === 0) setAddToGroupDisabled(true)
    else setAddToGroupDisabled(false)
  }, [ selectedAccounts ])

  // ************************ functions ************************
  const onEditClick = () => {
    if (commGroupID) {
      dispatch(seteditCommGroupID(commGroupID))
      dispatch(setCommGroupModalOpen(true))
    }
  }

  const onAddToGroupClick = () => {
    // If no rows were selected, navigate back to the group accounts page
    if (selectedAccounts.length === 0) {
      navigate(`/${ vanity }/communications/group/${ commGroupID }/accounts`)
      return
    }

    // Do something
    dispatch(addSocialAccounts({
      vars: {
        communicationGroupId: commGroupID || "",
        networkAccountIds: selectedAccounts,
      },
      onSuccess: () => {
        const toast: Toast = {
          type: "success",
          message: (selectedAccounts.length > 1)
            ? translate("Accounts successfully added to the Group!")
            : translate("Account successfully added to the Group!"),
        }
        dispatch(pushToast(toast))
        navigate(`/${ vanity }/communications/group/${ commGroupID }/accounts`)
      },
      onError: () => {
        const toast: Toast = {
          type: "error",
          message: translate("Failed to add the selected accounts(s) to the Communication Group!"),
        }
        dispatch(pushToast(toast))
      },
    }))
  }

  const rowSelectability = (params: GridRowParams<any>): boolean => {
    // Extract account id
    const userName = params.row.account.username

    // Check to see if the account exists
    const account = rawRows.find((row) => row.socialAccount.userName === userName)

    // Return true if the account does not exist (you can select one that doesn't exist)
    return account === undefined
  }

  function renderResultTypeIcon(resultType: GraphQL.GodSearchType): JSX.Element {
    const resultTypeIcon = resultType === GraphQL.GodSearchType.Social
      ? <AccountCircle />
      : <InsertPhotoOutlined />

    return (
      <div className="cp_component_search-results-type-dropdown">
        { resultTypeIcon }
        <ArrowDropDown />
      </div>
    )
  }

  // ************************ Pre-Render computations ************************

  // Render the component
  return (
    <div className="cp_component_communications-group-accounts-search-container">
      <div className="header-section">
        <SearchBar className="search-bar" onChange={ (e) => setSearchInputValue(e.target.value) } />
        <div className="details-container">
          <Dropdown
            buttonType="custom"
            customButtonChildren={
            renderResultTypeIcon(searchInput.resultType)
          }
          >
            <List disablePadding={ true }>
              <ListItem disablePadding={ true } disableGutters={ true }>
                <ListItemButton
                  onClick={ async () => {
                    const newInput = SearchHelper.cloneSearchInput(searchInput)
                    newInput.resultType = GraphQL.GodSearchType.Social
                    newInput.sortBy = SearchHelper.SearchFieldString.EngagementScore
                    await dispatch(setSearchInput(newInput))
                    dispatch(fetchSearchResults(true))
                    SearchHelper.setSearchInputQueryParams(
                      newInput,
                      searchParams,
                      setSearchParams,
                    )
                  } }
                >
                  <ListItemIcon>
                    <AccountCircle className="list-item-icon" />
                  </ListItemIcon>
                  <ListItemText primary={ translate("Accounts") } />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding={ true } disableGutters={ true }>
                <ListItemButton
                  onClick={ async () => {
                    const newInput = SearchHelper.cloneSearchInput(searchInput)
                    newInput.resultType = GraphQL.GodSearchType.Post
                    await dispatch(setSearchInput(newInput))
                    dispatch(fetchSearchResults(true))
                    SearchHelper.setSearchInputQueryParams(
                      newInput,
                      searchParams,
                      setSearchParams,
                    )
                  } }
                >
                  <ListItemIcon>
                    <InsertPhotoOutlined className="list-item-icon" />
                  </ListItemIcon>
                  <ListItemText primary={ translate("Content") } />
                </ListItemButton>
              </ListItem>
            </List>
          </Dropdown>
          <IconButton onClick={ onEditClick }>
            <EditOutlined />
          </IconButton>
          <Button
            className="add-account-button"
            label={ translate("+ ADD TO GROUP") }
            onClick={ onAddToGroupClick }
            disabled={ addToGroupDisabled }
          />
        </div>
      </div>
      <div className="breadcrumbs-section">
        <Button
          className="back-to-group-btn"
          isTextButton={ true }
          label={ translate("< Back to group") }
          onClick={ () => navigate(`/${ vanity }/communications/group/${ commGroupID }/accounts`) }
        />
      </div>
      <div className="communications-body">
        <div className="communications-body_search-form">
          <SearchFilter />
          <SearchFilterTabs />
          <aside className="cp_page_search-button">
            <Button
              isEnabled={ true }
              isPrimary={ true }
              label={ translate("Search") }
              onClick={ () => dispatch(fetchSearchResults(true)) }
            />
          </aside>
        </div>
        <div className="communications-body_search-results">
          <SearchResultsTable
            mountContext="search"
            isSelectable={ true }
            contentContextMenu={ {
              showAddPostButton: false,
              showViewMatchingPostsButton: true,
              showViewProfileButton: true,
            } }
            isRowSelectable={ rowSelectability }
            accountNameFilter={ searchInputValue }
          />
        </div>
      </div>
    </div>
  )
}
