import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
// import { Status } from "../../util/types"

// Edit URL Modal Slice Interface and Initial State
export interface EditURLModal {
  open: boolean,
  deliverableId?: string
  url: string
  updateCallback?: (edited: boolean) => Promise<void>
}

const initialState: EditURLModal = {
  open: false,
  deliverableId: undefined,
  url: "",
}

// Edit URL Modal Slice
export const EditURLModalSlice = createSlice({
  name: "EditURLModalSlice",
  initialState,
  reducers: {
    openEditURLModal: (
      state,
      action: PayloadAction<{
        url: string,
        deliverableId: string
        updateCallback?: (edited: boolean) => Promise<void>
      }>,
    ) => ({
      ...state,
      open: true,
      url: action.payload.url,
      deliverableId: action.payload.deliverableId,
      updateCallback: action.payload.updateCallback,
    }),
    closeEditURLModal: (
      state,
    ) => ({
      ...state,
      open: false,
      url: "",
      deliverableId: undefined,
    }),
  },
})

export const {
  openEditURLModal,
  closeEditURLModal,
} = EditURLModalSlice.actions
export default EditURLModalSlice.reducer

export const updateDeliverableUrl = (
  params: {
    variables: GraphQL.UpdateDeliverableLiveStatusUrlMutationVariables,
    onSuccess: () => any
    onError: () => any
  },
) => async () => {
  const result = await API.updateDeliverableUrl(params.variables)
  if (API.isSuccess(result)) {
    params.onSuccess()
  } else {
    params.onError()
  }
}
